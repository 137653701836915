// These are the default styles of the @onehat/ui components.
// App can override these with Functions/registerStyles()
const
	DEFAULT_FONTSIZE = 15,
	WHITE = '#fff',
	FOCUS = '#ffd';

const defaults = {
	ATTACHMENTS_MAX_FILESIZE: 1024 * 1024 * 5, // 5MB
	DEFAULT_WINDOW_WIDTH: 900,
	DEFAULT_WINDOW_HEIGHT: 800,
	FILTER_LABEL_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_ANCILLARY_TITLE_FONTSIZE: 22,
	FORM_ANCILLARY_DESCRIPTION_FONTSIZE: 16,
	FORM_COLOR_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_COLOR_INPUT_BG: WHITE,
	FORM_COLOR_INPUT_FOCUS_BG: FOCUS,
	FORM_COMBO_HEIGHT: '40px',
	FORM_COMBO_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_COMBO_INPUT_BG: WHITE,
	FORM_COMBO_INPUT_FOCUS_BG: FOCUS,
	FORM_COMBO_MENU_HEIGHT: 250,
	FORM_COMBO_MENU_MIN_WIDTH: 250,
	FORM_COMBO_TRIGGER_BG: WHITE,
	FORM_COMBO_TRIGGER_HOVER_BG: 'trueGray.300',
	FORM_DATE_ICON_BG: 'primary.200',
	FORM_DATE_ICON_BG_HOVER: 'primary.400',
	FORM_DATE_ICON_COLOR: WHITE,
	FORM_DATE_INPUT_BG: WHITE,
	FORM_DATE_INPUT_FOCUS_BG: FOCUS,
	FORM_DATE_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FIELDSET_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FIELDSET_BG: '#f6f6f6',
	FORM_FILE_ICON_BG: 'primary.200',
	FORM_FILE_ICON_BG_HOVER: 'primary.400',
	FORM_FILE_ICON_COLOR: WHITE,
	FORM_FILE_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FILE_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FILE_READOUT_BG: WHITE,
	FORM_INPUT_BG: WHITE,
	FORM_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_INPUT_FOCUS_BG: FOCUS,
	FORM_LABEL_WIDTH: '30%',
	FORM_LABEL_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_NUMBER_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TAG_MB: 1,
	FORM_TAG_MIN_HEIGHT: 10,
	FORM_TAG_PADDING: 1,
	FORM_TAG_VALUEBOX_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TAG_VALUEBOX_ICON_SIZE: 'sm',
	FORM_TEXT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TEXTAREA_BG: WHITE,
	FORM_TEXTAREA_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TEXTAREA_HEIGHT: 130,
	FORM_TOGGLE_BG: null,
	FORM_TOGGLE_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TOGGLE_SIZE: 'md',
	FORM_TOGGLE_ON_COLOR: '#0b0',
	FORM_TOGGLE_ON_HOVER_COLOR: '#090',
	FORM_TOGGLE_OFF_COLOR: '#f00',
	FORM_TOGGLE_OFF_HOVER_COLOR: '#c00',
	GRID_CELL_FONTSIZE: DEFAULT_FONTSIZE,
	GRID_HEADER_BG: '#eee',
	GRID_HEADER_HOVER_BG: '#ddd',
	GRID_HEADER_FONTSIZE: DEFAULT_FONTSIZE,
	GRID_HEADER_CELL_PX: 2,
	GRID_HEADER_CELL_PY: 3,
	GRID_HEADER_ICON_MT: 3,
	GRID_HEADER_ICON_MR: 2,
	GRID_HEADER_ICON_SIZE: 'sm',
	GRID_INLINE_EDITOR_BORDER_COLOR: 'primary.100',
	GRID_NAV_COLUMN_COLOR: '#aaa',
	GRID_CELL_PX: 2,
	GRID_CELL_PY: 3,
	GRID_ROW_BG: WHITE,
	GRID_ROW_ALTERNATE_BG: '#e5e5e5',
	GRID_ROW_HOVER_BG: 'hover',
	GRID_ROW_SELECTED_BG: 'selected',
	GRID_ROW_SELECTED_HOVER_BG: 'selectedHover',
	GRID_BORDER_WIDTH: 1,
	GRID_BORDER_COLOR: 'trueGray.300',
	ICON_BUTTON_BG: 'trueGray.200:alpha.0',
	ICON_BUTTON_BG_DISABLED: 'trueGray.200',
	ICON_BUTTON_BG_HOVER: '#000:alpha.20',
	ICON_BUTTON_BG_PRESSED: '#000:alpha.30',
	ICON_BUTTON_PX: 2,
	ICON_BUTTON_PY: 2,
	INLINE_EDITOR_MIN_WIDTH: 150,
	PANEL_FOOTER_BG: 'primary.100', // :alpha.50
	PANEL_HEADER_BORDER_BOTTOM_COLOR: 'trueGray.400',
	PANEL_HEADER_BORDER_BOTTOM_WIDTH: 1,
	PANEL_HEADER_BG: 'primary.100',
	PANEL_HEADER_BG_VERTICAL: 'primary.100',
	PANEL_HEADER_ICON_COLOR: WHITE,
	PANEL_HEADER_ICON_SIZE: 13,
	PANEL_HEADER_TEXT_COLOR: WHITE,
	PANEL_HEADER_TEXT_FONTSIZE: 15,
	PANEL_HEADER_PX: 3,
	PANEL_HEADER_PY: 1,
	REORDER_BORDER_COLOR: '#23d9ea',
	REORDER_BORDER_WIDTH: 4,
	REORDER_BORDER_STYLE: 'dashed',
	SLIDER_MIN_TRACK_COLOR: '#000',
	SLIDER_MAX_TRACK_COLOR: '#ccc',
	SLIDER_THUMB_COLOR: '#000',
	SLIDER_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	TAB_ACTIVE_BG: 'trueGray.200',
	TAB_ACTIVE_HOVER_BG: 'trueGray.200',
	TAB_ACTIVE_COLOR: 'primary.800',
	TAB_ICON_COLOR: WHITE,
	TAB_ACTIVE_ICON_COLOR: '#000',
	TAB_BAR_BG: 'primary.700',
	TAB_BG: 'primary.300',
	TAB_HOVER_BG: 'primary.500',
	TAB_COLOR: WHITE,
	TAB_FONTSIZE: DEFAULT_FONTSIZE,
	TEXT_FONTSIZE: DEFAULT_FONTSIZE,
	TREE_NODE_FONTSIZE: DEFAULT_FONTSIZE,
	TREE_NODE_PX: 2,
	TREE_NODE_PY: 3,
	TREE_NODE_BG: WHITE,
	TREE_NODE_HOVER_BG: 'hover',
	TREE_NODE_SELECTED_BG: 'selected',
	TREE_NODE_SELECTED_HOVER_BG: 'selectedHover',
	TREE_NODE_HIGHLIGHTED_BG: 'highlighted',
	TOOLBAR_ITEMS_COLOR: 'trueGray.800',
	TOOLBAR_ITEMS_DISABLED_COLOR: 'trueGray.400',
	TOOLBAR_ITEMS_ICON_SIZE: 'sm',
	TOOLBAR_PX: 2,
	TOOLBAR_PY: 2,
	VIEWER_ANCILLARY_FONTSIZE: 22,
};

export default defaults;
