import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 142.79 142.79"
		{...props}
		>
			<Path d="M0 0H142.79V142.79H0z" fill="none" />
			<Path d="M1.11 89.17h11.45v-36.8h-9.1v-4.15a30.25 30.25 0 0010.63-3.67H19v44.62h10.33v5.37H1.11zM33.12 90.6a4.67 4.67 0 114.66 4.88 4.71 4.71 0 01-4.66-4.88zM44.77 90.72c15-15 23.52-24 23.52-32.1 0-5.71-3.1-9.76-9.48-9.76-4.18 0-7.78 2.65-10.63 6l-3.74-3.64c4.18-4.59 8.69-7.6 15.16-7.6 9.24 0 15 5.83 15 14.72 0 9.38-8.62 18.7-20.41 31.1 2.72-.23 5.81-.44 8.42-.44h14.45v5.54H44.77z" />
			<Path d="M76.64 88.62l3.19-4.2c3 3 6.72 5.69 12.6 5.69 6.09 0 10.94-4.42 10.94-11.38s-4.21-11-10.61-11c-3.45 0-5.6 1.06-8.58 3l-3.42-2.17 1.65-24.07h25v5.54H88.07l-1.33 14.9a15.06 15.06 0 017.68-2.07c8.52 0 15.49 4.86 15.49 15.66s-8.15 17-16.83 17a21.92 21.92 0 01-16.44-6.9z" />
			<Path d="M121.34 74.66l-11.53-18.22h7l5.1 8.36c1.17 2.09 2.46 4.25 3.73 6.34h.31c1.15-2.09 2.29-4.25 3.44-6.34l4.61-8.36h6.72l-11.49 18.89 12.45 19.21h-7l-5.61-8.86c-1.33-2.29-2.72-4.64-4.14-6.84h-.32c-1.3 2.2-2.54 4.52-3.85 6.84l-5.17 8.86h-6.71z" />
		</Icon>
	)
}

export default SvgComponent
