import * as React from "react"
import Svg, { Defs, G, Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 488.63" {...props}>
			<Path d="M447.17 329.63H426.5l-61.85 61.85-61.85-61.85H191.68c0 19.33-15.67 35-35 35s-35-15.67-35-35H64.83C29.03 329.63 0 358.65 0 394.46v29.34c0 35.8 29.02 64.83 64.83 64.83h382.34c35.8 0 64.83-29.02 64.83-64.83v-29.34c0-35.8-29.02-64.83-64.83-64.83zm-15.17 104c-13.25 0-24-10.75-24-24s10.75-24 24-24 24 10.75 24 24-10.75 24-24 24z" />
			<Path d="M156.35 0L59.94 96.41c-7.81 7.81-7.81 20.47 0 28.28 7.81 7.81 20.48 7.81 28.28 0l48.46-48.46v253.4c0 11.05 8.95 20 20 20s20-8.95 20-20V76.9l47.8 47.8c3.9 3.91 9.02 5.86 14.14 5.86s10.24-1.95 14.14-5.86c7.81-7.81 7.81-20.47 0-28.28L156.35 0zM461.06 245.57c-7.81-7.81-20.47-7.81-28.29 0l-48.46 48.46V40.63c0-11.05-8.95-20-20-20s-20 8.95-20 20v252.73l-47.8-47.8c-7.81-7.81-20.47-7.81-28.29 0-7.81 7.81-7.81 20.47 0 28.29l96.41 96.41 96.41-96.41c7.81-7.81 7.81-20.47 0-28.29z" />
		</Icon>
	)
}

export default SvgComponent
