// Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
		<Path d="M128 288V64.03c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64c17.7 1.77 32-12.53 32-30.23zm353.5-58.9a66.276 66.276 0 001.875-15.64c0-22.7-11.44-43.13-29.28-55.28a65.97 65.97 0 00.64-9.122c0-22.32-11.06-42.6-28.83-54.83-2.437-34.71-31.47-62.2-66.8-62.2h-52.53c-35.94 0-71.55 11.87-100.3 33.41L169.6 92.93c-6.285 4.71-9.596 11.85-9.596 19.13 0 12.76 10.29 24.04 24.03 24.04 5.013 0 10.07-1.565 14.38-4.811l36.66-27.51c20.48-15.34 45.88-23.81 71.5-23.81h52.53c10.45 0 18.97 8.497 18.97 18.95 0 3.5-1.11 4.94-1.11 9.456 0 26.97 29.77 17.91 29.77 40.64 0 9.254-6.392 10.96-6.392 22.25 0 13.97 10.85 21.95 19.58 23.59 8.953 1.671 15.45 9.481 15.45 18.56 0 13.04-11.39 13.37-11.39 28.91 0 12.54 9.702 23.08 22.36 23.94C456.2 266.1 464 275.2 464 284.1c0 10.43-8.516 18.93-18.97 18.93H307.4c-12.44 0-24 10.02-24 23.1 0 4.038 1.02 8.078 3.066 11.72C304.4 371.7 312 403.8 312 411.2c0 8.044-5.984 20.79-22.06 20.79-12.53 0-14.27-.906-24.94-28.07-24.75-62.91-61.74-99.9-80.98-99.9-13.8 0-24.02 11.27-24.02 23.99 0 7.041 3.083 14.02 9.016 18.76C238.1 402 211.4 480 289.9 480c43.9 0 70.1-35 70.1-68.8 0-12.7-5.328-35.21-14.83-59.33h99.86C481.1 351.9 512 321.9 512 284.1c0-22.3-12.1-43.1-30.5-55z" />
		</Icon>
	)
}

export default SvgComponent
