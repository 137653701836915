// Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
      <Path d="M304 240V16.6c0-9 7-16.6 16-16.6 123.7 0 224 100.3 224 224 0 9-7.6 16-16.6 16H304zM32 272c0-121.3 90.1-221.7 207-237.7 9.2-1.3 17 6.1 17 15.4V288l156.5 156.5c6.7 6.7 6.2 17.7-1.5 23.1-39.2 28-87.2 44.4-139 44.4-132.5 0-240-107.4-240-240zm526.4 16c9.3 0 16.6 7.8 15.4 17-7.7 55.9-34.6 105.6-73.9 142.3-6 5.6-15.4 5.2-21.2-.7L320 288h238.4z" />
    </Icon>
  )
}

export default SvgComponent
