import * as React from "react"
import Svg, { Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
import { Icon } from 'native-base';

function SvgComponent(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 188.06 146.3"
      {...props}
    >
      <Defs></Defs>
      <Path
        d="M187.66 58.65c-27.43-37-49.47-56.1-67.37-58.41-11.21-1.45-18.06 4-22.58 7.66-1.3 1-3.08 2.47-3.68 2.47s-2.38-1.43-3.68-2.47C85.82 4.27 79-1.21 67.77.24 49.86 2.55 27.83 21.66.39 58.65a2 2 0 00-.39 1.5 127.23 127.23 0 0016.8 43C29.11 122.82 52.48 146.3 94 146.3s64.92-23.48 77.21-43.17a127.33 127.33 0 0016.79-43 2 2 0 00-.34-1.48zM68.28 4.2C77.8 3 83.61 7.62 87.84 11c2.34 1.89 4.16 3.37 6.16 3.37s3.85-1.48 6.18-3.35c4.24-3.4 10-8 19.57-6.82 16.42 2.13 38 21 64.15 56.14a124.43 124.43 0 01-8.05 25.32C164.31 106 140.63 131 94 131s-70.26-25-81.82-45.33a125.63 125.63 0 01-8.06-25.33C30.27 25.21 51.85 6.33 68.28 4.2z"
      />
      <Path
        d="M94 114.91c-33.23 0-54.92-15.37-67.27-28.26C13.4 72.69 8 58.94 7.74 58.36a1.08 1.08 0 01.35-1.24C63.19 14.88 76.37 22.75 86 28.5c2.73 1.63 5.08 3 8 3s5.31-1.41 8-3c9.62-5.75 22.8-13.62 77.89 28.62a1.08 1.08 0 01.35 1.24c-.22.58-5.65 14.33-19 28.29C149 99.54 127.26 114.91 94 114.91zm51.8-17.86c-3.09-10.5-25.09-18.63-51.8-18.63s-48.72 8.13-51.8 18.63c12.36 8.56 29.39 15.71 51.8 15.71s39.47-7.15 51.83-15.71zm-42.66-66.7c-2.88 1.72-5.6 3.34-9.14 3.34s-6.27-1.62-9.14-3.34c-6.61-4-15-9-39.75 4.45 7.41 8 26.49 13.76 48.89 13.76s41.48-5.73 48.88-13.76c-24.72-13.4-33.11-8.4-39.71-4.45z"
      />
    </Icon>
  )
}

export default SvgComponent
