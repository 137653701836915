/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const OpenSansCondensed_300Light = require('./OpenSansCondensed_300Light.ttf');
export const OpenSansCondensed_300Light_Italic = require('./OpenSansCondensed_300Light_Italic.ttf');
export const OpenSansCondensed_700Bold = require('./OpenSansCondensed_700Bold.ttf');
