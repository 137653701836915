// Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
		<Path d="M96 191.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64c17.67 0 32-14.33 32-31.1V223.1c0-16.8-14.3-32-32-32zM512 227c0-36.89-30.05-66.92-66.97-66.92h-99.86C354.7 135.1 360 113.5 360 100.8c0-33.8-26.2-68.78-70.06-68.78-46.61 0-59.36 32.44-69.61 58.5-31.66 80.5-60.33 66.39-60.33 93.47 0 12.84 10.36 23.99 24.02 23.99a23.88 23.88 0 0014.97-5.26c76.76-61.37 57.97-122.7 90.95-122.7 16.08 0 22.06 12.75 22.06 20.79 0 7.404-7.594 39.55-25.55 71.59a23.934 23.934 0 00-3.066 11.72c0 13.92 11.43 23.1 24 23.1h137.6C455.5 208.1 464 216.6 464 227c0 9.809-7.766 18.03-17.67 18.71-12.66.86-22.36 11.4-22.36 23.94 0 15.47 11.39 15.95 11.39 28.91 0 25.37-35.03 12.34-35.03 42.15 0 11.22 6.392 13.03 6.392 22.25 0 22.66-29.77 13.76-29.77 40.64 0 4.515 1.11 5.961 1.11 9.456 0 10.45-8.516 18.95-18.97 18.95h-52.53c-25.62 0-51.02-8.466-71.5-23.81l-36.66-27.51a23.851 23.851 0 00-14.38-4.811c-13.85 0-24.03 11.38-24.03 24.04 0 7.287 3.312 14.42 9.596 19.13l36.67 27.52C235 468.1 270.6 480 306.6 480h52.53c35.33 0 64.36-27.49 66.8-62.2 17.77-12.23 28.83-32.51 28.83-54.83a65.97 65.97 0 00-.64-9.122c17.84-12.15 29.28-32.58 29.28-55.28 0-5.311-.641-10.54-1.876-15.64C499.9 270.1 512 250.2 512 227z" />
		</Icon>
	)
}

export default SvgComponent
