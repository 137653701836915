import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 142.79 142.79"
		{...props}
		>
			<Path d="M0 0H142.79V142.79H0z" fill="none" />
			<Path d="M33.65 90.6a4.67 4.67 0 114.67 4.88 4.71 4.71 0 01-4.67-4.88zM44.11 88.62l3.18-4.2c3 3 6.72 5.69 12.61 5.69 6.08 0 10.93-4.42 10.93-11.38s-4.21-11-10.6-11c-3.46 0-5.61 1.06-8.58 3l-3.43-2.17 1.65-24.07h25v5.54H55.53l-1.32 14.9a15 15 0 017.67-2.07c8.52 0 15.49 4.86 15.49 15.66s-8.15 17-16.82 17a21.92 21.92 0 01-16.44-6.9z" />
			<Path d="M88.8 74.66L77.27 56.44h7l5.09 8.36c1.17 2.09 2.47 4.25 3.73 6.34h.32c1.15-2.09 2.29-4.25 3.44-6.34l4.65-8.36h6.72L96.7 75.33l12.45 19.21h-7l-5.61-8.86c-1.32-2.29-2.71-4.64-4.14-6.84h-.31c-1.31 2.2-2.55 4.52-3.86 6.84l-5.16 8.86h-6.72z" />
		</Icon>
	)
}

export default SvgComponent
