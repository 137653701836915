import Panel from './Panel.js';

// TODO: Flesh this out!

export default function UploadDownload(props) {
	return <Panel
				model="UploadDownload"
				{...props}
			/>;
}
