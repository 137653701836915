// Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
	return (
		<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" {...props}>
		<Path d="M208 0c114.9 0 208 78.8 208 176s-93.1 176-208 176c-18.7 0-36.8-2.3-54.1-6.2-30.6 19-74.77 38.2-128.95 38.2-9.98 0-19.02-5.9-22.932-15.1-3.914-9.2-2.025-19.8 4.721-27 .521-.4 22.641-24.5 38.991-56C17.18 255.8 0 217.6 0 176 0 78.8 93.13 0 208 0zm-43.4 298.1c14.6 4.2 29.2 5.9 43.4 5.9 88.2 0 160-57.4 160-128S296.2 48 208 48 48 105.4 48 176c0 35.2 17.71 61.2 32.57 76.9l23.53 24.9-15.79 30.3c-3.57 6-7.58 13.8-11.76 20.4 17.71-5.1 35.15-13 52.15-24.4l16.7-9.5 19.2 3.5zm277-169.9C552 132.4 640 209.5 640 304c0 41.6-17.2 79.8-45.7 109.9 16.3 31.5 38.4 55.6 39 56 6.7 7.2 8.6 17.8 3.8 27-3 9.2-12.1 15.1-22.1 15.1-54.1 0-98.3-19.2-128.9-38.2-17.3 3.9-35.4 6.2-54.1 6.2-82 0-152.9-40.2-186.8-98.5 17.3-2.3 33.9-6.2 49.7-11.6 28 37.2 79 62.1 137.1 62.1 14.2 0 28.8-1.7 43.4-5.9l19.2-3.5 16.7 9.5c17 11.4 34.4 19.3 52.2 24.4-4.2-6.6-8.2-14.4-11.8-20.4l-15.8-30.3 23.5-24.9c14.9-15.6 32.6-41.7 32.6-76.9 0-66.3-63.3-120.9-144.9-127.4l.9-.6c0-16.5-2.2-32.5-6.4-47.8z" />
		</Icon>
	)
}

export default SvgComponent
